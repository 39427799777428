<script>
import NavBar from '@/components/NavBar';
import SectionOne from '@/Generic/components/SectionOne/index.vue';
import SectionTwo from '@/Generic/components/SectionTwo/index.vue';
import SectionThree from '@/Generic/components/SectionThree/index.vue';
import SectionFour from '@/Generic/components/SectionFour/index.vue';
import SectionFive from '@/Generic/components/SectionFive/index.vue';
import SectionPlans from '@/Generic/components/SectionPlans/index.vue';
import Divider from '@/Generic/components/Divider/index.vue';
import Footer from '@/Generic/components/Footer/index.vue';
import Services from './services';

export default {
  head: {
    meta: [
      {
        name: 'description',
        content:
          'Divida os principais serviços de assinatura de streamings, músicas, cursos, leitura, escritório e jogos e economize até 80% no valor final.',
      },
    ],
    link: [
      {
        rel: 'canonical',
        href: window.location.href,
      },
    ],
  },

  components: {
    NavBar,
    Divider,
    Footer,
    SectionOne,
    SectionTwo,
    SectionFive,
    SectionFour,
    SectionThree,
    SectionPlans,
  },

  data: () => ({
    settings: {
      routePath: '',
      service: 'Serviço',
      theme: '#000000',
      themeGradient: null,
      faq: 'default',
      malePronoun: false,
      possession: false,
      sectionOne: {},
      sectionFour: {},
      sectionFive: {},
    },
  }),

  beforeRouteEnter(to, _, next) {
    const path = to.params?.path;

    if (typeof path === 'string') {
      const {
        service,
        theme,
        faq,
        routePath,
        sectionOne = {},
        sectionFour = {},
        sectionFive = {},
        malePronoun = false,
        themeGradient = null,
        possession = false,
      } = Services.find((service) => {
        return service.routePath.toLowerCase().replace(/\//g, '') === path.toLowerCase();
      }) || {};

      if (service && (theme || themeGradient)) {
        next((vm) => {
          Object.assign(vm.settings, {
            faq,
            theme,
            service,
            routePath,
            possession,
            sectionOne,
            sectionFive,
            malePronoun,
            sectionFour,
            themeGradient,
          });
        });

        document.title = `Kotas - ${service}`;
      } else {
        next('/');
      }
    }
  },

  watch: {
    'settings.routePath': {
      immediate: true,
      async handler(routePath) {
        if (routePath) {
          await this.$nextTick();
          await this.$nextTick();

          const navElement = document.querySelector('.nav');
          navElement?.setAttribute('dark', 'true');
        }
      },
    },
  },

  beforeDestroy() {
    const navElement = document.querySelector('.nav');
    navElement.setAttribute('dark', 'false');
  },
};
</script>

<template>
  <div class="generic">
    <template v-if="settings.routePath">
      <NavBar :target="`service-${settings.routePath}`" />
    </template>

    <div class="generic__inner">
      <SectionOne :settings="settings" />
    </div>

    <Divider />

    <div class="generic__inner generic__section-two">
      <SectionTwo :settings="settings" />
    </div>

    <Divider />

    <div class="generic__inner">
      <SectionThree :settings="settings" />
    </div>

    <Divider />

    <div class="generic__inner">
      <SectionFour :settings="settings" />
    </div>

    <Divider />

    <div class="generic__inner">
      <SectionPlans :settings="settings" />
    </div>

    <divider />

    <div class="generic__inner">
      <SectionFive :settings="settings" />
    </div>

    <Divider />
    <div class="generic__inner">
      <Footer />
    </div>
  </div>
</template>

<style lang="scss">
@import './index.scss';
</style>
