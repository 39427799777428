<script>
import Button from '@/Generic/components/Button/index.vue';
import Share from '@/Generic/svg/share.svg';
import Security from '@/Generic/svg/security.svg';
import Money from '@/Generic/svg/money.svg';
import Computer from '@/Generic/svg/computer.svg';
import SectionCol from '@/Generic/components/SectionCol/index.vue';

export default {
  name: 'SectionThree',

  components: {
    Button,
    Share,
    Money,
    Security,
    Computer,
    SectionCol,
  },

  props: {
    settings: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    cards: [
      {
        title: 'Economia',
        description: 'Economize até 80% em suas assinaturas e ganhe créditos por indicação!',
        icon: 'Money',
      },
      {
        title: 'Divisão',
        description:
          'Reúna pessoas com interesses em comum ao seu e compartilhe seus serviços por assinatura.',
        icon: 'Share',
      },
      {
        title: 'Conteúdo variado',
        description:
          'Diversas plataformas pré cadastradas, e você ainda pode cadastrar um serviço personalizado.',
        icon: 'Computer',
      },
      {
        title: 'Segurança',
        description: 'Sem dores de cabeça! Garantimos a segurança dos dados dos nossos usuários.',
        icon: 'Security',
      },
    ],
  }),
};
</script>
<template>
  <SectionCol
    class="section-three"
    button-label="Fazer Cadastro"
    button-to="https://app.kotas.com.br/cadastro"
    :button-theme="settings.theme"
    :button-gtm="{
      action: 'click',
      category: `service-${settings.routePath}`,
      label: '3-cta-fazer-cadastro',
    }"
  >
    <template #h2> Benefícios </template>

    <template #p>
      Fazer parte da família <span>Kotas</span> é economizar com segurança! Não tenha mais dor de
      cabeça para dividir suas assinaturas de serviços digitais com seus amigos.
    </template>

    <div class="section-three__cards">
      <div
        v-for="(card, index) in cards"
        :key="`card-section-three-${index}`"
        class="section-three__card"
      >
        <component
          :is="card.icon"
          :fill="settings.themeGradient ? settings.themeGradient.color : settings.theme"
        />
        <h3>{{ card.title }}</h3>
        <p>{{ card.description }}</p>
      </div>
    </div>
  </SectionCol>
</template>

<style lang="scss" scoped>
@import './sectionThree.scss';
</style>
