<script>
function changeHexOpacity(hex, opacity) {
  const hexWithoutHash = hex.replace('#', '');
  const hexWithoutHashWithOpacity = hexWithoutHash + Math.round(opacity * 255).toString(16);
  return `#${hexWithoutHashWithOpacity}`;
}

export default {
  props: {
    highlight: Boolean,
    to: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
    theme: {
      type: String,
      required: false,
    },
    themeGradient: {
      type: Object,
      required: false,
    },
    gtm: {
      type: Object,
      required: false,
    },
  },

  data: () => ({
    key: Symbol(),
  }),

  watch: {
    gtm: {
      deep: true,
      handler() {
        this.key = Symbol();
      },
    },
    themeGradient: {
      immediate: true,
      handler(themeGradient) {
        if (themeGradient) {
          this.setButtonTheme({
            gradient: true,
            background: themeGradient.background,
            hoverColor: themeGradient.color,
            hoverBackground: themeGradient.background,
            highlightBackground: themeGradient.color,
          });
        }
      },
    },
    theme: {
      immediate: true,
      handler(theme) {
        if (theme) {
          this.setButtonTheme({
            background: theme,
            hoverColor: theme,
            hoverBackground: theme,
            highlightBackground: theme,
          });
        }
      },
    },
  },

  methods: {
    setButtonTheme({
      background,
      hoverColor,
      hoverBackground,
      highlightBackground,
      gradient = false,
    }) {
      const style = document.documentElement.style;
      style.setProperty('--button-background', background);
      style.setProperty('--button-hover-color', changeHexOpacity(hoverColor, 0.3));
      style.setProperty(
        '--button-hover-background',
        gradient ? hoverBackground : changeHexOpacity(hoverBackground, 0.9)
      );
      style.setProperty(
        '--button-highlight-background',
        changeHexOpacity(highlightBackground, 0.15)
      );
    },

    onClick() {
      if (this.to) {
        window.open(this.to, '_self');
      }
    },
  },
};
</script>

<template>
  <button
    v-bind="$attrs"
    v-on="$listeners"
    v-gtm.click="gtm"
    :key="key"
    class="button"
    :class="{ 'button--highlight': highlight }"
    @click="onClick"
  >
    <slot>
      {{ label }}
    </slot>
  </button>
</template>

<style lang="scss" scoped>
@import './Button.scss';
</style>

<style>
:root {
  --button-background: '#000000';
  --button-hover-color: transparent;
  --button-hover-background: transparent;
  --button-highlight-background: transparent;
}
</style>
