var render = function render(){var _vm=this,_c=_vm._self._c;return _c('SectionCol',{attrs:{"tittle-dense":"","button-label":"Ver todos os planos","button-to":`https://app.kotas.com.br/pesquisa?noCache=true`,"button-gtm":{
    action: 'click',
    category: `service-${_vm.settings.routePath}`,
    label: '5-cta-ver-todos-os-planos',
  }},scopedSlots:_vm._u([{key:"h2",fn:function(){return [_vm._v(" Conheça outros planos do "),_c('strong',[_vm._v(" Kotas ")])]},proxy:true},{key:"default",fn:function(){return [_c('PlansCategories',{attrs:{"service-name":_vm.settings.routePath},model:{value:(_vm.categorySelected),callback:function ($$v) {_vm.categorySelected=$$v},expression:"categorySelected"}}),_c('div',{staticClass:"section-plans__group"},_vm._l((_vm.data),function(item){return _c('div',{key:`service-${item.name}`,staticClass:"section-plans__card"},[_c('div',{staticClass:"section-plans__card__badge"},[_vm._v(_vm._s(item.discount)+"% mais barato")]),_c('div',{staticClass:"section-plans__card__header",style:({ color: item.color })},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"section-plans__card__body"},[_c('div',{staticClass:"section-plans__card__body__plan"},[_c('p',[_vm._v("Escolha seu plano")]),_c('div',{staticClass:"section-plans__card__body__plan__time",style:({ color: item.color })},[_vm._v(" MENSAL ")])]),_c('div',{staticClass:"section-plans__card__body__price"},[_c('div',{staticClass:"section-plans__card__body__price__original"},[_vm._v(" Preço oficial: "+_vm._s(item.priceOriginal)+" ")]),_c('div',{staticClass:"section-plans__card__body__price__kotas"},[_c('div',{staticClass:"section-plans__card__body__price__kotas__label"},[_vm._v(" Pelo "),_c('span',[_vm._v("Kotas")]),_vm._v(" R$ ")]),_c('div',{staticClass:"section-plans__card__body__price__kotas__value"},[_vm._v(" "+_vm._s(item.priceKotas)+" ")])])]),_c('div',{staticClass:"section-plans__card__body__footer"},[_c('p',[_vm._v("Sem fidelidade. Cancele quando quiser")]),(_vm.settings.routePath)?_c('button',{directives:[{name:"gtm",rawName:"v-gtm.click",value:({
                action: 'click',
                category: `service-${_vm.settings.routePath}`,
                label: `cta-${item.gtm}`,
              }),expression:"{\n                action: 'click',\n                category: `service-${settings.routePath}`,\n                label: `cta-${item.gtm}`,\n              }",modifiers:{"click":true}}],on:{"click":function($event){return _vm.openService(item.name)}}},[_vm._v(" Economize já ")]):_vm._e()])])])}),0)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }