import { render, staticRenderFns } from "./Kotas.vue?vue&type=template&id=12ed878a&scoped=true&"
import script from "./Kotas.vue?vue&type=script&lang=js&"
export * from "./Kotas.vue?vue&type=script&lang=js&"
import style0 from "./Kotas.vue?vue&type=style&index=0&id=12ed878a&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12ed878a",
  null
  
)

export default component.exports