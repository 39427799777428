export default [
  {
    routePath: 'spotify',
    theme: '#00D856',
    service: 'Spotify',
    faq: 'default',
    malePronoun: true,
    sectionOne: {
      mock: 'spotify.mp4',
    },
  },
  {
    routePath: 'youtube-premium',
    theme: '#FE0000',
    service: 'Youtube Premium',
    malePronoun: true,
    sectionOne: {
      mock: 'youtube-premium.mp4',
    },
    faq: 'default',
  },
  {
    routePath: 'canva',
    theme: '#00C3CC',
    service: 'Canva',
    malePronoun: true,
    sectionOne: {
      mock: 'canva.mp4',
    },
    faq: 'default',
  },
  {
    routePath: 'vpn',
    theme: '#4483F7',
    service: 'VPN',
    malePronoun: false,
    sectionOne: {
      mock: 'vpns.mp4',
    },
    faq: 'default',
  },
  {
    routePath: 'duolingo',
    theme: '#58CC02',
    service: 'Duolingo',
    malePronoun: true,
    sectionOne: {
      mock: 'duolingo.mp4',
    },
    faq: 'default',
  },
  {
    routePath: 'deezer',
    malePronoun: true,
    themeGradient: {
      color: '#5862f1',
      background: 'linear-gradient(74deg, rgba(88,98,241,1) 0%, rgba(59,177,178,1) 81%)',
      faq: 'default',
    },
    service: 'Deezer',
    sectionOne: {
      mock: 'deezer.mp4',
    },
    faq: 'default',
  },
  {
    routePath: 'gerenciador-de-senhas',
    theme: '#177CE2',
    malePronoun: true,
    service: 'Gerenciador de Senhas',
    sectionOne: {
      mock: 'gerenciadores-senha.mp4',
    },
    faq: 'default',
  },
  {
    routePath: 'jornal',
    theme: '#3EC2CF',
    service: 'Jornal',
    malePronoun: true,
    possession: true,
    sectionOne: {
      mock: 'jornal.mp4',
    },
    faq: 'default',
  },
  {
    routePath: 'antivirus',
    theme: '#DFCB02',
    service: 'Antivírus',
    malePronoun: true,
    possession: true,
    sectionOne: {
      mock: 'antivirus.mp4',
    },
    faq: 'default',
  },
  {
    routePath: 'ebooks',
    theme: '#FF5B00',
    service: 'E-books',
    malePronoun: true,
    possession: true,
    faq: 'default',
    sectionOne: {
      title: 'Compartilhe os seus',
      mock: 'ebooks.mp4',
    },
    faq: 'default',
  },
  {
    routePath: 'kotas',
    theme: '#01B1EC',
    service: 'Kotas',
    faq: 'kotas',
    sectionOne: {
      mock: 'kotas.mp4',
      title: 'Economize com o',
      subtitle: 'Economize até 70% em seus <br/> serviços digitais favoritos.',
      description: `Forme grupos de assinaturas para dividir custos com colegas de casa, amigos
                    e familiares de uma forma inteligente`,
    },
    sectionFour: {
      circleTwo: {
        description: 'Diversos serviços por assinatura para escolher!',
      },
    },
    sectionFive: {
      footer: 'Clique no botão abaixo para economizar até 80% das suas assinaturas com o Kotas',
    },
  },
];
