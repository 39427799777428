<script>
import AccordionOpen from '@/Generic/svg/accordion-open.svg';
import AccordionClose from '@/Generic/svg/accordion-close.svg';

export default {
  name: 'AccordionItem',

  components: {
    AccordionOpen,
    AccordionClose,
  },

  props: {
    title: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
    service: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    id: Symbol(),
    key: Symbol(),
  }),

  watch: {
    service() {
      this.key = Symbol();
    },
  },

  computed: {
    isOpen() {
      return this.$parent.active === this.id;
    },
  },

  methods: {
    toggle() {
      const { $parent } = this;
      $parent.active = $parent.active === this.id ? null : this.id;
    },
  },
};
</script>

<template>
  <div class="accordion__item">
    <div class="accordion__item__header">
      <span class="accordion__item__header__title">
        <slot name="title">
          {{ title }}
        </slot>
      </span>
      <div
        v-gtm.click="{
          label,
          action: 'click',
          category: `service-${service}`,
        }"
        :key="key"
        class="accordion__item__header__action"
        @click="toggle"
      >
        <AccordionOpen v-if="isOpen" height="29" width="29" />
        <AccordionClose v-else height="29" width="29" />
      </div>
    </div>

    <div v-show="isOpen" class="accordion__item__body">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import './accordionItem.scss';
</style>
