var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"section-two"},[_vm._m(0),_c('div',{staticClass:"section-two__content"},[_vm._m(1),_c('p',[_vm._v(" O Kotas te ajuda a dividir suas assinaturas de streamings e outros serviços com sua família e amigos de forma fácil, segura e econômica. Com o Kotas, você sempre recebe a sua parte na data certa, sem precisar esquentar a cabeça com atrasos e calotes. E tem mais: ao se cadastrar, você consegue encontrar grupos abertos de diversos serviços com futuros amigos dispostos a dividir a assinatura com você! ")]),_c('div',{staticClass:"section-two__content__action"},[_c('a',{attrs:{"href":"https://app.kotas.com.br/cadastro"}},[_c('Button',{attrs:{"gtm":{
            action: 'click',
            category: `service-${_vm.settings.routePath}`,
            label: '2-cta-fazer-cadastro',
          },"label":"Fazer Cadastro","theme":_vm.settings.theme}})],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-two__img"},[_c('img',{attrs:{"src":"https://assets.kotas.com.br/img/landing/servicos/groups.png","alt":"Grupos do Kotas"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_vm._v("Por que usar o "),_c('span',[_vm._v("Kotas?")])])
}]

export { render, staticRenderFns }