<script>
import Button from '@/Generic/components/Button/index.vue';

export default {
  name: 'SectionTwo',

  components: {
    Button,
  },

  props: {
    settings: {
      type: Object,
      required: true,
    },
  },
};
</script>
<template>
  <section class="section-two">
    <div class="section-two__img">
      <img
        src="https://assets.kotas.com.br/img/landing/servicos/groups.png"
        alt="Grupos do Kotas"
      />
    </div>

    <div class="section-two__content">
      <h2>Por que usar o <span>Kotas?</span></h2>
      <p>
        O Kotas te ajuda a dividir suas assinaturas de streamings e outros serviços com sua família
        e amigos de forma fácil, segura e econômica. Com o Kotas, você sempre recebe a sua parte na
        data certa, sem precisar esquentar a cabeça com atrasos e calotes. E tem mais: ao se
        cadastrar, você consegue encontrar grupos abertos de diversos serviços com futuros amigos
        dispostos a dividir a assinatura com você!
      </p>

      <div class="section-two__content__action">
        <a href="https://app.kotas.com.br/cadastro">
          <Button
            :gtm="{
              action: 'click',
              category: `service-${settings.routePath}`,
              label: '2-cta-fazer-cadastro',
            }"
            label="Fazer Cadastro"
            :theme="settings.theme"
          />
        </a>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import './sectionTwo.scss';
</style>
