<script>
import Accordion from '@/Generic/components/Accordion/index.vue';
import AccordionItem from '@/Generic/components/Accordion/item.vue';

export default {
  components: {
    AccordionItem,
    Accordion,
  },

  props: {
    settings: {
      type: Object,
      required: true,
    },
  },
};
</script>

<template>
  <div class="section-five__accordion">
    <Accordion>
      <AccordionItem
        :service="settings.routePath"
        label="faq-beneficios-de-gerenciar-suas-assinaturas-com-o-kotas"
      >
        <template #title>
          Benefícios de gerenciar suas assinaturas com o <span>Kotas</span>
        </template>

        <div class="section-five__accordion__item">
          <p>
            Compartilhar suas assinaturas digitais pelo Kotas é a forma mais fácil de economizar
            aquela grana, mas de forma segura e organizada.
          </p>
          <p>
            Seja criando um grupo próprio ou participando como membro em um grupo existente, você
            tem vantagens que só o Kotas oferece a você:
          </p>

          <strong> Maior economia </strong>

          <p>Com o Kotas você economiza até 80% do valor da assinatura do seu serviço digital.</p>

          <strong> Grupos compartilhados </strong>
          <p>
            Crie seu grupo de assinatura e convide seus amigos ou participe de grupos abertos por
            outras pessoas para dividir a assinatura do seu serviço favorito.
          </p>

          <strong> Variedade de serviços </strong>

          <p>ão mais de 500 serviços para você dividir assinatura.</p>

          <strong> Ganhe créditos </strong>
          <p>Quanto mais amigos você chamar, mais créditos você ganha na sua conta!</p>

          <strong> Segurança </strong>

          <p>
            Fazemos o rateio do valor da assinatura entre membros e administradores de forma justa e
            segura, para que não ocorra atrasos e nenhuma parte seja prejudicada.
          </p>
        </div>
      </AccordionItem>

      <AccordionItem
        :service="settings.routePath"
        :label="`faq-por-que-assinar-um-plano-do-kotas-para-usar-a-${settings.service}`"
      >
        <template #title>
          Por que assinar um plano do <span>Kotas</span> para usar
          {{
            ['Jornal', 'Antivírus'].includes(settings.service)
              ? `dividir sua assinatura de ${settings.service}?`
              : `${settings.malePronoun ? 'o' : 'a'} ${settings.service}?`
          }}
        </template>
        <div class="section-five__accordion__item">
          <p>
            Dividir suas assinaturas digitais de streamings e outros sites é uma boa forma de
            economizar no valor da mensalidade.
          </p>

          <p>
            Muitas pessoas já fazem a assinatura
            {{ settings.possession ? 'de' : settings.malePronoun ? 'do' : 'da' }}
            {{ settings.service }} e compartilham sua assinatura informalmente com a família.
          </p>

          <p>
            Ao usar o Kotas para gerenciar sua assinatura {{ settings.malePronoun ? 'do' : 'da' }}
            {{ settings.service }}, você garante que cada pessoa do seu grupo irá pagar na data
            correta, sem atrasos e sem que você tenha que se preocupar em cobrar ninguém.
          </p>

          <p>
            Inclusive, se um membro quiser sair do seu grupo de assinatura
            {{ settings.malePronoun ? 'do' : 'da' }} {{ settings.service }}, em nossa plataforma é
            possível encontrar outras pessoas para preencher a vaga.
          </p>
        </div>
      </AccordionItem>

      <AccordionItem
        :service="settings.routePath"
        :label="`faq-afinal-como-compartilhar-a-assinatura-${settings.malePronoun ? 'do' : 'da'}-${
          settings.service
        }`"
        :title="`Afinal, como compartilhar a assinatura ${settings.malePronoun ? 'do' : 'da'} ${
          settings.service
        }?`"
      >
        <div class="section-five__accordion__item">
          <p>
            Se você já tem uma assinatura {{ settings.malePronoun ? 'do' : 'da' }}
            {{ settings.service }}, é muito fácil de compartilhá-la no Kotas:
          </p>

          <ul>
            <li>
              <strong> Cadastre-se no Kotas: </strong>
              <p>
                Faça seu cadastro e valide seu email e número de telefone para começar a usar nossa
                plataforma.
              </p>
            </li>

            <li>
              <strong> Crie o seu grupo de {{ settings.service }}: </strong>
              <p>
                Crie o seu grupo de {{ settings.service }}: clique em Criar Grupo no topo da tela.
                Aí, basta escolher o serviço {{ settings.malePronoun ? 'do' : 'da' }}
                {{ settings.service }}, escolher o tipo de plano da sua assinatura e preencher o
                restante das informações do formulário. E pronto! Agora você já pode começar a
                convidar outros membros para o seu grupo.
              </p>
            </li>
          </ul>
        </div>
      </AccordionItem>

      <AccordionItem
        title="Quais são as formas de pagamento?"
        :service="settings.routePath"
        label="faq-quais-sao-as-formas-de-pagamento"
      >
        <div class="section-five__accordion__item">
          <p>
            No Kotas, nós disponibilizamos as principais formas de pagamento para você carregar seus
            créditos no site: boleto bancário, PIX, cartão de crédito, débito, PicPay, Mercado Pago
            e outros.
          </p>
        </div>
      </AccordionItem>

      <AccordionItem
        title="O que pode acontecer se algum integrante sair do grupo?"
        :service="settings.routePath"
        label="faq-o-que-pode-acontecer-se-algum-integrante-sair-do-grupo"
      >
        <div class="section-five__accordion__item">
          <p>
            No Kotas, nós cobramos uma caução no valor da assinatura como forma de segurança para o
            administrador e os membros do grupo.
          </p>

          <p>
            Quando você participa de um grupo, você paga a primeira e a última mensalidade de uma
            vez. Esse valor é usado quando você for cancelar pagando sua última mensalidade, ou
            estornado pra você se solicitado no prazo (15 dias antes do vencimento de sua fatura).
          </p>

          <p>
            Dessa forma, o administrador não tem prejuízo em caso de inadimplência e pode se
            organizar para substituir o membro que saiu.
          </p>

          <p>
            Além disso, o membro também pode desistir de sair do grupo durante esse período e
            retomar a assinatura.
          </p>
        </div>
      </AccordionItem>
    </Accordion>
  </div>
</template>
<style scoped lang="scss">
@import './sectionFiveAccordion.scss';
</style>
