<script>
import SectionCol from '@/Generic/components/SectionCol/index.vue';
import ArrowTop from '@/Generic/svg/arrow-top.svg';
import ArrowBottom from '@/Generic/svg/arrow-bottom.svg';

export default {
  name: 'SectionFour',

  components: {
    SectionCol,
    ArrowTop,
    ArrowBottom,
  },

  props: {
    settings: {
      type: Object,
      required: true,
    },
  },

  computed: {
    circleTwoDescription() {
      const { sectionFour, service } = this.settings;
      return sectionFour?.circleTwo?.description
        ? sectionFour?.circleTwo?.description
        : `Diversos serviços ${service} por assinatura para escolher!`;
    },
    theme() {
      const { themeGradient, theme } = this.settings;
      return themeGradient
        ? {
            background: themeGradient.background,
            'background-clip': 'text',
            'text-fill-color': 'transparent',
            '-webkit-text-fill-color': 'transparent',
            '-webkit-background-clip': 'text',
          }
        : { color: theme };
    },
  },
};
</script>

<template>
  <SectionCol
    class="section-four"
    button-label="Fazer Cadastro"
    :button-theme="settings.theme"
    button-to="https://app.kotas.com.br/cadastro"
    :button-gtm="{
      action: 'click',
      category: `service-${settings.routePath}`,
      label: '4-cta-fazer-cadastro',
    }"
  >
    <template #h2> Se interessou? E agora? </template>

    <template #p>
      Compartilhar sua assinatura no <span>Kotas</span> é fácil. Basta se cadastrar no site,
      encontrar ou criar um grupo do serviço que preferir, escolher seu meio de pagamento e pronto:
      já pode começar a economizar!
    </template>

    <template #default>
      <div class="section-four__timeline">
        <div class="section-four__line">
          <span :style="theme">Cadastre-se</span>
          <p>
            Economize até 80% em suas assinaturas {{ settings.service }} e ganhe créditos por
            indicação!
          </p>
        </div>

        <div class="section-four__arrow-top">
          <ArrowTop
            :fill="settings.themeGradient ? settings.themeGradient.color : settings.theme"
          />
        </div>

        <div class="section-four__line">
          <span :style="theme">
            Encontre ou <br />
            crie seu grupo
          </span>
          <p>{{ circleTwoDescription }}</p>
        </div>

        <div class="section-four__arrow-bottom">
          <ArrowBottom
            :fill="settings.themeGradient ? settings.themeGradient.color : settings.theme"
          />
        </div>

        <div class="section-four__line">
          <span :style="theme">
            Pague ou receba <br />
            dos membros.
          </span>
          <p>Garantia de pagamento, evite dores de cabeça!</p>
        </div>
      </div>
    </template>
  </SectionCol>
</template>

<style lang="scss" scoped>
@import './SectionFour.scss';
</style>
