<script>
import { Categories } from '../../enums';
import db from './db';
import SectionCol from '@/Generic/components/SectionCol/index.vue';
import PlansCategories from '../PlansCategories';

export default {
  components: {
    PlansCategories,
    SectionCol,
  },

  props: {
    settings: {
      type: Object,
      required: false,
    },
  },

  data: () => ({
    categorySelected: Categories.VIDEO,
  }),

  computed: {
    data() {
      return db?.[this.categorySelected] || db[Categories.VIDEO];
    },
  },

  methods: {
    openService(servico) {
      window.open(
        `https://app.kotas.com.br/pesquisa?servico=${encodeURIComponent(servico)}&noCache=true`,
        '_self'
      );
    },
  },
};
</script>

<template>
  <SectionCol
    tittle-dense
    button-label="Ver todos os planos"
    :button-to="`https://app.kotas.com.br/pesquisa?noCache=true`"
    :button-gtm="{
      action: 'click',
      category: `service-${settings.routePath}`,
      label: '5-cta-ver-todos-os-planos',
    }"
  >
    <template #h2>
      Conheça outros planos do
      <strong> Kotas </strong>
    </template>

    <template #default>
      <PlansCategories v-model="categorySelected" :service-name="settings.routePath" />

      <div class="section-plans__group">
        <div v-for="item in data" :key="`service-${item.name}`" class="section-plans__card">
          <div class="section-plans__card__badge">{{ item.discount }}% mais barato</div>
          <div :style="{ color: item.color }" class="section-plans__card__header">
            {{ item.name }}
          </div>
          <div class="section-plans__card__body">
            <div class="section-plans__card__body__plan">
              <p>Escolha seu plano</p>
              <div :style="{ color: item.color }" class="section-plans__card__body__plan__time">
                MENSAL
              </div>
            </div>

            <div class="section-plans__card__body__price">
              <div class="section-plans__card__body__price__original">
                Preço oficial: {{ item.priceOriginal }}
              </div>
              <div class="section-plans__card__body__price__kotas">
                <div class="section-plans__card__body__price__kotas__label">
                  Pelo <span>Kotas</span> R$
                </div>

                <div class="section-plans__card__body__price__kotas__value">
                  {{ item.priceKotas }}
                </div>
              </div>
            </div>
            <div class="section-plans__card__body__footer">
              <p>Sem fidelidade. Cancele quando quiser</p>
              <button
                v-if="settings.routePath"
                v-gtm.click="{
                  action: 'click',
                  category: `service-${settings.routePath}`,
                  label: `cta-${item.gtm}`,
                }"
                @click="openService(item.name)"
              >
                Economize já
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </SectionCol>
</template>

<style lang="scss" scoped>
@import './sectionPlans.scss';
</style>

<style lang="scss">
.swiper-container {
  width: 100%;

  .swiper-wrapper {
    display: flex;
    gap: 1rem;
  }
}
</style>
