<script>
export default {
  name: 'Accordion',

  data: () => ({
    active: null,
  }),
};
</script>

<template>
  <div class="accordion">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
@import './accordion.scss';
</style>
