<script>
import SectionCol from '@/Generic/components/SectionCol/index.vue';
import Default from './Default.vue';
import Kotas from './Kotas.vue';

export default {
  components: {
    Kotas,
    Default,
    SectionCol,
  },

  props: {
    settings: {
      type: Object,
      required: true,
    },
  },

  computed: {
    footer() {
      const { sectionFive, service, malePronoun } = this.settings;
      return sectionFive?.footer
        ? sectionFive.footer
        : `Clique no botão abaixo para economizar até 80% da sua assinatura ${
            malePronoun ? 'do' : 'da'
          } ${service}`;
    },
  },
};
</script>

<template>
  <SectionCol
    button-label="Quero Economizar"
    :button-theme="settings.theme"
    button-to="https://app.kotas.com.br/cadastro"
    :button-gtm="{
      action: 'click',
      category: `service-${settings.routePath}`,
      label: '6-cta-quero-economizar',
    }"
  >
    <template #h2> Outras dúvidas </template>

    <template #p>
      Se você ainda tem dúvidas, vale a pena conferir a nossa base de conhecimentos
      <a href="https://kotas.movidesk.com/kb/" rel="noreferrer"> clicando aqui </a>. Caso esteja
      tendo algum outro tipo de problema, nosso suporte esta a sua disposição, basta nos enviar um
      ticket <a href="https://www.kotas.com.br/contato" rel="noreferrer">clicando aqui</a>.
    </template>

    <template #default>
      <Default v-if="settings.faq === 'default'" :settings="settings" />
      <Kotas v-else-if="settings.faq === 'kotas'" :settings="settings" />
    </template>

    <template #action>
      <p class="section-five__action">
        {{ footer }}
      </p>
    </template>
  </SectionCol>
</template>

<style lang="scss" scoped>
@import './sectionFive.scss';
</style>
