<script>
import Button from '@/Generic/components/Button/index.vue';

export default {
  name: 'SectionOne',

  components: {
    Button,
  },

  props: {
    settings: {
      type: Object,
      required: true,
    },
  },

  computed: {
    content() {
      const { sectionOne, service, malePronoun, possession } = this.settings;
      return {
        mock: `https://assets.kotas.com.br/videos/landing/servicos/${sectionOne?.mock}`,
        title: sectionOne?.title
          ? sectionOne?.title
          : `Compartilhe ${malePronoun ? 'o seu' : 'a sua'}`,
        subtitle:
          sectionOne?.subtitle ||
          `Divida sua assinatura ${
            possession ? 'de' : malePronoun ? 'do' : 'da'
          } ${service} e <br/> economize com o <strong>Kotas!</strong>`,
        description:
          sectionOne?.description ||
          `No Kotas você compartilha sua assinatura ${
            possession ? 'de' : malePronoun ? 'do' : 'da'
          } ${service} de forma fácil <br /> e segura, economizando até 80% no valor da mensalidade.`,
      };
    },
    theme() {
      const { themeGradient, theme } = this.settings;
      return themeGradient
        ? {
            background: themeGradient.background,
            'background-clip': 'text',
            'text-fill-color': 'transparent',
            '-webkit-text-fill-color': 'transparent',
            '-webkit-background-clip': 'text',
          }
        : { color: theme };
    },
  },
};
</script>

<template>
  <section class="section-one">
    <div class="section-one__content">
      <div class="section-one__content__title">
        <h4 v-html="content.title"></h4>
        <h1 :style="theme">{{ settings.service }}</h1>
      </div>

      <div class="section-one__content__body">
        <h2 v-html="content.subtitle"></h2>

        <p v-html="content.description"></p>

        <div class="section-one__content__body__action">
          <a href="https://app.kotas.com.br/cadastro">
            <Button
              :gtm="{
                action: 'click',
                category: `service-${settings.routePath}`,
                label: '1-cta-quero-economizar',
              }"
              label="Quero Economizar"
              :theme="settings.theme"
              :theme-gradient="settings.themeGradient"
              highlight
            />
          </a>
        </div>
      </div>
    </div>

    <div class="section-one__mock">
      <div class="section-one__mock__shadow"></div>
      <video class="section-one__mock__video" autoplay loop muted>
        <source :src="content.mock" type="video/mp4" />
      </video>
    </div>
  </section>
</template>

<style scoped lang="scss">
@import './sectionOne.scss';
</style>
