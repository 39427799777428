import Video from './Video.vue';
import Music from './Music.vue';
import Certificate from './Certificate.vue';
import Briefcase from './Briefcase.vue';
import Book from './Book.vue';

export default {
  Book,
  Briefcase,
  Certificate,
  Music,
  Video,
};
