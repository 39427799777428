<script>
import Button from '@/Generic/components/Button/index.vue';

export default {
  name: 'SectionCol',

  components: {
    Button,
  },

  props: {
    tittleDense: Boolean,
    buttonLabel: {
      type: String,
      required: false,
    },

    buttonTheme: {
      type: String,
      required: false,
    },

    buttonTo: {
      type: String,
      required: false,
    },

    buttonGtm: {
      type: Object,
      required: false,
    },
  },
};
</script>

<template>
  <section class="section-three">
    <div class="section-three__title" :class="{ 'section-three__title--dense': tittleDense }">
      <h2><slot name="h2" /></h2>
      <p><slot name="p" /></p>
    </div>

    <slot />

    <div class="section-three__action">
      <slot name="action" />
      <a :href="buttonTo">
        <Button v-if="buttonLabel" :label="buttonLabel" :theme="buttonTheme" :gtm="buttonGtm" />
      </a>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import './SectionCol.scss';
</style>
