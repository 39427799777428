<script>
import Facebook from '@/Generic/svg/facebook.svg';
import Instagram from '@/Generic/svg/instagram.svg';
import Twitter from '@/Generic/svg/twitter.svg';

export default {
  name: 'Footer',

  components: {
    Facebook,
    Instagram,
    Twitter,
  },
};
</script>
<template>
  <footer class="footer">
    <div class="footer__left">
      <div class="footer__left__social-media">
        <a
          href="https://www.facebook.com/kotasoficial/"
          rel="noreferrer"
          target="_blank"
          aria-label="Facebook do Kotas"
        >
          <Facebook height="30" width="30" />
        </a>
        <a
          href="https://www.instagram.com/kotas_oficial/"
          rel="noreferrer"
          taget="_blank"
          aria-label="Instagram do Kotas"
        >
          <Instagram height="30" width="30" />
        </a>
        <a
          href="https://x.com/kotasoficial"
          rel="noreferrer"
          target="_blank"
          aria-label="X do Kotas"
        >
          <Twitter height="30" width="30" />
        </a>
      </div>
      <div class="footer__left__info">
        Av. Cristiano Machado, 1648 - Salas 801 e 802 - <br />
        Cidade Nova, Belo Horizonte - MGKOTAS SERVIÇOS DE INTERNET <br />
        LTDA, CNPJ 27.656.455/0001-16
      </div>
    </div>

    <div class="footer__right">
      <ul>
        <li>
          <a href="https://www.kotas.com.br/blog/termos-de-uso/"> Termos de uso </a>
        </li>
        <li>
          <a href="https://www.kotas.com.br/blog/politica-de-privacidade/">
            Política de privacidade
          </a>
        </li>
        <li>
          <a href="https://www.kotas.com.br/blog/"> Blog </a>
        </li>
        <li>
          <a href="https://www.kotas.com.br/contato"> Contato </a>
        </li>
        <li>
          <a href="https://www.kotas.com.br/r/#art-taxas"> Taxas </a>
        </li>
      </ul>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
@import './footer.scss';
</style>
