<script>
import { Categories } from '../../enums';
import icons from '../../icons';

const CATEGORIES = [
  {
    label: 'Vídeo',
    gtm: 'video',
    value: Categories.VIDEO,
  },
  {
    label: 'Musica',
    gtm: 'musica',
    value: Categories.MUSICA,
  },
  {
    label: 'Cursos',
    gtm: 'cursos',
    value: Categories.CURSOS,
  },
  /* Desativado por enquanto */
  // {
  //   label: 'Leitura',
  //   gtm: 'leitura',
  //   value: Categories.LEITURA,
  // },
  {
    label: 'Escritório',
    gtm: 'escritorio',
    value: Categories.ESCRITORIO,
  },
];

export default {
  components: {
    ...icons,
  },

  data: () => ({
    categories: CATEGORIES,
  }),

  props: {
    value: {
      type: Number,
      required: true,
    },
    serviceName: {
      type: String,
      required: false,
    },
  },

  methods: {
    updateValue(value) {
      this.$emit('input', value);
    },
    getCategoryIcon(value) {
      const map = {
        [Categories.VIDEO]: 'Video',
        [Categories.MUSICA]: 'Music',
        [Categories.CURSOS]: 'Certificate',
        [Categories.LEITURA]: 'Book',
        [Categories.ESCRITORIO]: 'Briefcase',
      };

      return map?.[value];
    },
  },
};
</script>
<template>
  <div v-if="serviceName" class="plans-categories">
    <div
      v-for="category in categories"
      v-gtm.click="{
        action: 'click',
        category: `service-${serviceName}`,
        label: `categoria-${category.gtm}`,
      }"
      :key="`category-${category.value}`"
      class="plans-categories__item"
      :class="{ 'plans-categories__item--active': category.value === value }"
      @click="updateValue(category.value)"
    >
      <component :is="getCategoryIcon(category.value)" height="20" width="20" />
      {{ category.label }}
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import './PlansCategories.scss';
</style>
