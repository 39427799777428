import { Categories } from '../../enums';

const videos = [
  {
    discount: 77,
    name: 'Youtube Premium',
    priceOriginal: '41,90',
    priceKotas: '9,48',
    color: '#FE0000',
    gtm: 'youtube-premium',
  },
  {
    discount: 50,
    name: 'Mubi',
    priceOriginal: '34,90',
    priceKotas: '17,45',
    color: '#081ca9',
    gtm: 'mubi',
  },
  {
    discount: 64,
    name: 'Rakuten Viki',
    priceOriginal: '35,03',
    priceKotas: '12,70',
    color: '#119efe',
    gtm: 'rakuten-viki',
  },
  {
    discount: 52,
    name: 'Lionsgate+',
    priceOriginal: '14,90',
    priceKotas: '7,22',
    color: '#086979',
    gtm: 'lionsgate-plus',
  },
];

const music = [
  {
    discount: 77,
    name: 'Spotify',
    priceOriginal: '34,90',
    priceKotas: '8,32',
    color: '#00D856',
    gtm: 'spotify',
  },
  {
    discount: 77,
    name: 'Deezer',
    priceOriginal: '39,90',
    priceKotas: '9,15',
    color: '#121216',
    gtm: 'deezer',
  },
  {
    discount: 73,
    name: 'Tidal',
    priceOriginal: '25,35',
    priceKotas: '6,72',
    color: '#000000',
    gtm: 'tidal',
  },
  {
    discount: 73,
    name: 'Qobuz',
    priceOriginal: '34,99',
    priceKotas: '9,48',
    color: '#FCAF2E',
    gtm: 'qobuz',
  },
];

const course = [
  {
    discount: 72,
    name: 'English Fish',
    priceOriginal: '69,90',
    priceKotas: '19,98',
    color: '#FF5F26',
    gtm: 'english-fish',
  },
  {
    discount: 76,
    name: 'English Attack',
    priceOriginal: '33,90',
    priceKotas: '8,15',
    color: '#D36D70',
    gtm: 'english-attack',
  },
  {
    discount: 42,
    name: 'Nath Finanças',
    priceOriginal: '29,90',
    priceKotas: '17,45',
    color: '#01A6BE',
    gtm: 'nath-financas',
  },
  {
    discount: 81,
    name: 'Masterclass',
    priceOriginal: '130,41',
    priceKotas: '24,23',
    color: '#E32652',
    gtm: 'masterclass',
  },
];

const read = [
  // {
  //   discount: 68,
  //   name: 'Folha de São Paulo',
  //   priceOriginal: '28,49',
  //   priceKotas: '9,15',
  //   color: '#043654',
  //   gtm: 'folha-de-sao-paulo',
  // },
  // {
  //   discount: 67,
  //   name: 'GoRead',
  //   priceOriginal: '24,90',
  //   priceKotas: '8,22',
  //   color: '#EA1368',
  //   gtm: 'go-read',
  // },
  // {
  //   discount: 70,
  //   name: 'Ubook',
  //   priceOriginal: '24,90',
  //   priceKotas: '7,48',
  //   color: '#FF5B00',
  //   gtm: 'ubook',
  // },
  // {
  //   discount: 68,
  //   name: 'Toca Livros',
  //   priceOriginal: '19,90',
  //   priceKotas: '6,48',
  //   color: '#205F87',
  //   gtm: 'toca-livros',
  // },
];

const office = [
  {
    discount: 76,
    name: 'Canva',
    priceOriginal: '69,80',
    priceKotas: '16,46',
    color: '#00C3CC',
    gtm: 'canva',
  },
  {
    discount: 76,
    name: 'Google One',
    priceOriginal: '34,99',
    priceKotas: '8,33',
    color: '#5F6368',
    gtm: 'google-one',
  },
  {
    discount: 80,
    name: 'NordVPN',
    priceOriginal: '73,00',
    priceKotas: '14,67',
    color: '#4483F7',
    gtm: 'nord-vpn',
  },
  {
    discount: 71,
    name: '1Password',
    priceOriginal: '28,29',
    priceKotas: '8,16',
    color: '#051627',
    gtm: '1-password',
  },
  {
    discount: 81,
    name: 'Dashlane',
    priceOriginal: '28,29',
    priceKotas: '5,33',
    color: '#09363F',
    gtm: 'dashlane',
  },
];

export default {
  [Categories.VIDEO]: videos,
  [Categories.MUSICA]: music,
  [Categories.CURSOS]: course,
  [Categories.LEITURA]: read,
  [Categories.ESCRITORIO]: office,
};
